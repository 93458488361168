<template>
  <base-icon-button-menu-book :title="title"
                              @click="onClick"/>
</template>

<script>
  import BaseIconButtonMenuBook from '@/components/baseComponents/baseIcons/BaseIconButtonMenuBook';

  export default {
    name: 'ActionOpenProposalFullDetails',
    components: { BaseIconButtonMenuBook },
    props: {
      proposal: {
        type: Object,
        required: true,
      },
      title: {
        type: String,
        default: 'Full details',
        validator: (value) => value.length > 0,
      },
    },
    computed: {
      onClick() {
        return this.$listeners.click || this.goToProposalHistory;
      },
    },
    methods: {
      goToProposalHistory() {
        const { _id, document_specification } = this.proposal;
        this.openProposalHistory(_id.$oid, document_specification.type);
      },
      openProposalHistory(id, environment) {
        const routeResolve = this.$router.resolve({
          name: 'ProposalFullDetailsDashboard',
          params: { id, environment },
        });
        window.open(routeResolve.href, '_blank');
      },
    },
  };
</script>

<style scoped>

</style>
