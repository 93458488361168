<template>
  <b-btn
    v-b-tooltip.topleft.hover
    v-on="$listeners"
    :aria-label="title"
    :title="activeTitle"
    class="base-icon-btn">
    <slot/>
  </b-btn>
</template>

<script>
  export default {
    name: 'BaseIconButton',
    props: {
      title: {
        type: String,
        default: '',
      },
    },
    computed: {
      activeTitle() {
        return this.$parent.$attrs.disabled ? false : this.title;
      },
    },
  };
</script>

<style scoped lang="scss">
  .base-icon-btn {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 24px;
    padding: 0;
    width: 24px;
  }

  //Double .base-icon-btn was used to override bootstrap btn-secondary class
  .base-icon-btn,
  .base-icon-btn:active,
  .base-icon-btn:focus,
  .base-icon-btn.base-icon-btn:active:focus,
  {
    color: $color-teal;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .base-icon-btn:hover:not([disabled]) {
    transform: scale(1.2);
  }
</style>
