<template>
  <div v-if="loading"
       class="text-center justify-content-between">
    <b-spinner class="align-middle text-danger my-2"/>
    <strong>Loading...</strong>
  </div>
  <div v-else-if="!loading && is_data_valid()">
    <b-row class="ma-1">
      <b-col cols="auto">
        <b-pagination v-if="pagination"
                      v-model="current_page"
                      :per-page="perPage"
                      :total-rows="data_length"
                      size="md"/>
      </b-col>
      <b-col cols="auto">
        <slot/>
      </b-col>
    </b-row>
  </div>
  <div v-else-if="!loading && not_first_load">
    <component :is="`h${headerSize}`"
               class="text-center"> No data to display
    </component>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      data_length: undefined,
      currentPage: {
        type: Number,
        default: 1,
      },
      perPage: {
        type: Number,
        default: 20,
      },
      pagination: {
        type: Boolean,
        default: true,
      },
      headerSize: {
        type: Number,
        default: 1,
        validator: (val) => [1, 2, 3, 4, 5, 6].includes(val),
      },
    },
    data() {
      return {
        not_first_load: false,
        loading: this.value,
        current_page: this.currentPage,
      };
    },
    methods: {
      is_data_valid() {
        return this.data_length > 0;
      },
    },
    watch: {
      value() {
        this.loading = this.value;
      },
      current_page() {
        this.$emit('change_page', this.current_page);
      },
    },
    updated() {
      this.not_first_load = true;
    },
  };
</script>

<style scoped>

</style>
