<!--suppress ALL -->
<template>
  <b-container class="max-width-100"
               fluid>

    <slot name="header">
      <p class="ma-2">You can add Co-Authors to your proposal and set permissions to edit the proposal (Proposal
        editor).</p>
      <p class="ma-2">You can also add Experimentalists who will participate in experiment at SOLARIS after proposal
        acceptation.</p>
      <p class="ma-2">The member you want to add must be a registered user and have the appropriate consent accepted</p>
      <p class="ma-2 red--text">To add member type at least 6 signs of their e-mail address and choose from given email
        list.</p>
      <p class="ma-2 red--text">To remove member uncheck all roles and click button "Confirm roles and send
        invitation"</p>
    </slot>

    <b-table
        :current-page="currentPage"
        :fields="fields"
        :items="members"
        :per-page="perPage"
        bordered
        class="child-flex"
        small
        striped>

      <template v-slot:cell(full_name)="row">
        <span v-model="row.item.full_name">{{ row.item.full_name }}</span>
      </template>

      <template v-slot:cell(email)="row">
        <custom-single-select
            v-if="!row.item.is_submitted && selector_on"
            v-model="row.item.email"
            :editable="editable"
            :minSearchLength=5
            :options="emails"
            :required="true"
            @input="clear_row($event, row)"
        ></custom-single-select>
        <span v-else
              v-model="row.item.email">{{ row.item.email }}</span>
        <span v-if="row.item._rowVariant"
              class="red--text font-weight-bold">Email duplicated</span>
      </template>

      <template v-slot:cell(main_author)="row">
        <div v-if="row.item.roles.includes('MAIN_AUTHOR')">
          <b-form-checkbox v-model="row.item.roles"
                           :disabled="row.item.roles.includes('MAIN_AUTHOR')"
                           value="MAIN_AUTHOR">
          </b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(coauthor)="row">
        <div v-if="!row.item.roles.includes('MAIN_AUTHOR') && row.item.email">
          <b-form-checkbox v-model="row.item.roles"
                           :disabled="!editable"
                           value="CO_AUTHOR"
                           @change="touch(row)">
          </b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(proposal_editor)="row">
        <div v-if="!row.item.roles.includes('MAIN_AUTHOR') && row.item.email">
          <b-form-checkbox v-model="row.item.roles"
                           :disabled="!editable"
                           value="PROPOSAL_EDITOR"
                           @change="touch(row)">
          </b-form-checkbox>
        </div>
      </template>

      <template v-slot:cell(experimentalist)="row">
        <div v-if="!row.item.roles.includes('MAIN_AUTHOR') && row.item.email">
          <b-form-checkbox v-model="row.item.roles"
                           :disabled="!editable"
                           value="EXPERIMENTALIST"
                           @change="touch(row)">
          </b-form-checkbox>
        </div>
      </template>

    </b-table>

    <b-row>
      <b-col class="mr-auto p-3"
             cols="auto">
        <b-pagination v-if="members.length > perPage"
                      v-model="currentPage"
                      :per-page="perPage"
                      :total-rows="members.length"
                      class="my-0"/>
      </b-col>
      <b-col class="p-3"
             cols="auto">
        <b-button v-if="is_new_member"
                  :disabled="disabled"
                  class="float-left action-buttons"
                  variant="info"
                  @click="submit_members()">
          Confirm roles and send invitation
        </b-button>
      </b-col>
    </b-row>

  </b-container>
</template>

<script>
  import CustomSingleSelect from '@/components/custom-single-select';
  import { includesAny } from '@/helpers';

  export default {
    name: 'team_form',
    components: { CustomSingleSelect },
    props: {
      editable: {
        type: Boolean,
        default: true,
      },
      selector_on: {
        type: Boolean,
        default: true,
      },
      proposalObjectId: {
        type: String,
        required: true,
      },
      oldCryoemTeamRolesMapping: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        fields: ['full_name', 'email', 'main_author', 'coauthor', 'proposal_editor', 'experimentalist'],
        emails: [],
        perPage: 10,
        members: [],
        members_sent: [],
        currentPage: 1,
        is_new_member: false,
        disabled: false,
      };
    },
    computed: {
      areOldCryoemRolesInTeamMembers() {
        const oldCryoemTeamRoles = ['principle_investigator', 'main_proposer', 'experimentalist'];
        return this.members.some((member) => includesAny(member.roles, oldCryoemTeamRoles));
      },
    },
    methods: {
      check_if_empty(row) {
        if (row.item.roles.length == 0 && this.hasProperty(row.item, 'is_submitted')) {
          this.members.splice(row.index + (this.perPage * (this.currentPage - 1)), 1);
        }
      },
      clear_row($event, row) {
        if (!$event) {
          row.item.roles = [];
          delete row.item._rowVariant;
          this.disabled = !this.is_team_changed(row);
        }
      },
      get_emails() {
        this.axios.get('/users/emails')
          .then((resp) => {
            this.emails = resp.data;
          });
      },
      is_team_changed(new_el) {
        const old_el = this.members_sent;
        const new_roles = new_el.item.roles;
        const multiply = this.perPage * (this.currentPage - 1);
        const old_roles = old_el[new_el.index + multiply].roles;
        // if additional person is added or deleted
        if (new_el.index >= old_el.length || new_roles.length === 0 && new_el.item.length === old_el.length) {
          return true;
        }
        if (new_roles.length >= old_roles.length) {
          // check every element of new_roles if is included in old_roles
          return !new_roles.every((v) => old_roles.includes(v));
        }
        if (new_roles.length < old_roles.length) {
          return !old_roles.every((v) => new_roles.includes(v));
        }
        return false;
      },
      make_members_copy() {
        this.members_sent = JSON.parse(JSON.stringify(this.members));
      },
      touch(row) {
        row.item.dirty = true;
        this.is_new_member = true;
        // change event is executed before this.members object is updated ->
        // due to this, setTimeout had to be implemented
        setTimeout(() => {
          this.disabled = !this.is_team_changed(row);
          this.check_if_empty(row);
        }, 0);
      },
      add_member() {
        this.members.push({
          full_name: '',
          email: '',
          roles: [],
        });
      },
      load_members() {
        this.axios.get(`/documents/${this.proposalObjectId}/team`)
          .then((resp) => {
            this.members = resp.data;
            this.add_member();
            this.make_members_copy();
            this.mapOldCryoemTeamRoles();
          });
      },
      mapOldCryoemTeamRoles() {
        if (this.oldCryoemTeamRolesMapping && !this.editable && this.areOldCryoemRolesInTeamMembers) {
          this.members.forEach((member) => {
            if (member.roles.includes('principle_investigator') || member.roles.includes('main_proposer')) {
              member.roles.push('PROPOSAL_EDITOR');
            }
            if (member.roles.includes('experimentalist')) {
              member.roles.push('EXPERIMENTALIST');
            }
          });
        }
      },
      before_submit() {
        const email_array = [];
        for (const member of this.members) {
          if (email_array.indexOf(member.email) < 0) {
            email_array.push(member.email);
          } else {
            this.$set(member, '_rowVariant', 'danger');
            return false;
          }
        }
        return true;
      },
      filter_members(members) {
        const filtered = members.filter((member) => member.email !== '');
        return filtered;
      },
      submit_members() {
        if (this.before_submit()) {
          this.disabled = true;
          this.axios.post(`/documents/${this.proposalObjectId}/team`, this.filter_members(this.members))
            .then((resp) => {
              if (resp.status == 200) {
                this.members = resp.data;
                this.add_member();
                this.make_members_copy();
                this.$notify({ type: 'success', title: 'Team changed successfully' });
              } else {
                this.load_members();
                this.$notify({ type: 'error', title: 'Something went wrong' });
              }
            })
            .catch((err) => {
              this.$notify({ type: 'error', title: 'Something went wrong' });
            });
        }
      },
    },
    created() {
      this.load_members();
      if (this.editable) {
        this.get_emails();
      }
      this.$emit('teamLoaded');
    },
  };
</script>

<style scoped>
  .action-buttons {
    margin-left: 5px;
    margin-right: 5px;
  }

  table {
    table-layout: fixed;
    width: 100%;
  }

  /*Fix for style leak.
    When table-layout is fixed and b-container has fluid,
    max-width mustn't be given in %.*/
  .max-width-100 {
    max-width: 100vw;
  }
</style>
