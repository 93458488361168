<template>
  <base-icon-button-expand-less-more v-bind="$attrs"
                                     :isExpanded="bTableRow.detailsShowing"
                                     :title="bTableRow.detailsShowing ? title.shown : title.hidden"
                                     @click="onClick"
  />
</template>

<script>
  import BaseIconButtonExpandLessMore from '@/components/baseComponents/baseIcons/BaseIconButtonExpandLessMore';

  export default {
    name: 'BaseActionExpandDetails',
    components: { BaseIconButtonExpandLessMore },
    props: {
      bTableRow: {
        type: Object,
        required: true,
      },
      title: {
        type: Object,
        default: () => ({
          hidden: 'Show details',
          shown: 'Hide details',
        }),
        validator: (value) => 'hidden' in value && 'shown' in value,
      },
    },
    computed: {
      listeners() {
        return { click: this.bTableRow.toggleDetails, ...this.$listeners };
      },
      onClick() {
        return this.$listeners.click || this.bTableRow.toggleDetails;
      },
    },
  };
</script>

<style scoped>

</style>
