const password_regex = {
  // Regex requires minimum:
  //     - 1 lower,
  //     - 1 upper,
  //     - 1 numeric,
  //     - 1 special value,
  //     - 0 spacebars,
  //     - 8 length.
  //
  // For example: ex@mPle3 or exaMPLe#

  regex: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\\W])(?:(?=[\\x00-\\x7F])\\S){8,}$'),
};

const date_regex = {
  // Regex requires subsequent:
  //     - year:
  //           - first digit should be a number 1 or 2
  //           - the next 3 digits should be any numbers (from 0 to 9)
  //     - the character '-'
  //     - month:
  //           - first digit should be 0 or 1
  //           - if first digit is 0 then the second one should be a number from 1 to 9
  //           - if first digit is 1 then the second one should be a number from 0 to 2
  //     - the character '-'
  //     - day:
  //           - first digit should be a number from 0 to 3
  //           - if first digit is 0 then the second one should be a number from 1 to 9
  //           - if first digit is 1 or 2 then the second one should be any number (from 0 to 9)
  //           - if first digit is 3 then the second one should be a number from 0 to 1.
  //
  // For example: 1999-03-19 or 2021-06-09

  regex: new RegExp('([12]\\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\\d|3[01]))$'),
};

const notation_number_regex = {
  // Regex requires numbers and allow to use 'e' in notation.
  //
  // For example:
  //     - 3
  //     - 42.7
  //     - 7,3453
  //     - 1.23e10
  //     - 89,41111e+10
  //     - 2e-10
  // but also it permits e-2 or e2
  //
  // Warning: regex permits to use ',' so if you want to change string to number,
  // you would need to use replace(',', '.')

  regex: new RegExp(/^(\d*)([.,])?(\d*)?(([eE])\^?([-+])?(\d*))?$/),
};

const email_regex = {
  // Regex requires a valid email address consists of an email prefix and an email domain.
  // The prefix appears to the left of the @ symbol.
  // The domain appears to the right of the @ symbol.
  //
  // For example: example@mail.com or Example.mail@Mail.example.pl

  // eslint-disable-next-line vue/max-len
  regex: new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/),
};

const documentIdCericRegex = /^\d{8}\/\d{2}$/;

export { password_regex, date_regex, notation_number_regex, email_regex, documentIdCericRegex };
