<template>
  <div id="proposals_list">

    <b-row>
      <b-col cols="10">
        <h5 class="red--text">All fields are optional.</h5>
      </b-col>
      <b-col cols="2">

        <b-form-group>
          <div class="right div--button w-100">
            <span>
              Filtered rows: {{ proposalsLength }}
            </span>
          </div>
        </b-form-group>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="2">
        <label class="font-weight-bold mt-3">Proposals infrastructures to load:</label>
      </b-col>
      <b-col cols="2">
        <label class="font-weight-bold mt-3">Proposals states to load:</label>
      </b-col>
      <b-col cols="2">
        <label class="font-weight-bold mt-3">Proposals grades:</label>
      </b-col>
      <b-col cols="4">
        <label class="font-weight-bold mt-3">Proposals types:</label>
      </b-col>
      <b-col cols="2">
        <label class="font-weight-bold mt-3">Display CERIC proposals:</label>
      </b-col>
    </b-row>

    <b-row>
      <b-col v-for="type in infrastructures"
             :key="type.value"
             cols="2">
        <b-form-group>
          <b-form-checkbox-group v-model="query.infrastructures"
                                 :options="type"
                                 button-variant="outline-secondary"
                                 buttons
                                 class="w-100"
                                 stacked/>
        </b-form-group>
      </b-col>

      <b-col v-for="state in proposalStates"
             :key="state.value"
             cols="2">
        <b-form-group>
          <b-form-checkbox-group v-model="query.proposal_states"
                                 :options="state"
                                 button-variant="outline-secondary"
                                 buttons
                                 class="w-100 checkbox-group--rose"
                                 stacked/>
        </b-form-group>
      </b-col>
      <b-col v-for="state in proposalGrades"
             :key="state.value"
             cols="2">
        <b-form-group>
          <b-form-checkbox-group v-model="query.proposal_grades"
                                 :options="state"
                                 button-variant="outline-secondary"
                                 buttons
                                 class="w-100 checkbox-group--rose"
                                 stacked/>
        </b-form-group>
      </b-col>
      <b-col v-for="state in proposalTypes"
             :key="state.value"
             cols="4">
        <b-form-group>
          <b-form-checkbox-group v-model="query.proposal_types"
                                 :options="state"
                                 button-variant="outline-secondary"
                                 buttons
                                 class="w-100 checkbox-group--rose"
                                 stacked/>
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-form-group>
          <b-form-radio-group v-model="displayCeric"
                              :options="yesNo"
                              button-variant="outline-secondary"
                              buttons
                              class="w-100"
                              stacked/>
        </b-form-group>
        <b-button class="w-100"
                  variant="info"
                  @click="openModalGroupMails">
          Send group mails
        </b-button>
        <basic-mailer-form ref="mailerFormModal"
                           :filter-query="this.filterQuery"
                           :is-proposals-submitted-state="this.isProposalStateChosen('submitted')"
                           :mail-content="this.mailContent"
                           :number-of-valid-names-to-send="this.numberOfValidNamesToSend"/>
      </b-col>
    </b-row>

    <b-row align-v="center"
           class="mb-3">
      <b-col cols="1"
             md="auto">
        Filter by call:
      </b-col>
      <b-col cols="2"
             md="auto">
        <b-form-select v-model="selectedCall"
                       :options="callsView"
                       @change="chooseCall"/>
      </b-col>
    </b-row>

    <b-input-group class="mb-3"
                   prepend="List proposals from - to">
      <b-form-input v-model="query.from"
                    :class="{'invalid': $v.query.from.$error}"
                    aria-label="From"
                    placeholder="YYYY-MM-DD"
                    @input="$v.query.from.$touch()"/>
      <b-form-input v-model="query.to"
                    :class="{'invalid': $v.query.to.$error}"
                    aria-label="To"
                    placeholder="YYYY-MM-DD"
                    @input="$v.query.to.$touch()"/>
    </b-input-group>

    <b-button id="loadProposals"
              class="w-100 mt-2 mb-2"
              variant="info"
              @click="loadData">
      Load proposals
    </b-button>

    <b-table :current-page="currentPage"
             :fields="fields"
             :items="workingProposals"
             :per-page="perPage"
             head-variant="light"
             hover
             outlined
             stacked="lg"
             striped>

      <template v-slot:top-row="{fields}">
        <proposal-filter :id="id"
                         v-model="proposals"
                         :fields="fields"
                         :not_include_key="['actions', 'ordinalNumber']"
                         @input="clearWorkingProposals"/>
      </template>

      <template v-slot:cell(ordinalNumber)="row">
        <base-column-ordinal-number v-bind="{index: row.index, perPage, currentPage}"/>
      </template>

      <template v-slot:cell(states.date)="row">
        <div v-if="row.item.states.name === 'submitted'">
          <span v-text="row.item.states.date"/>
        </div>
      </template>

      <template v-slot:cell(document_specification.subtype)="row">
        <div v-if="row.item.document_specification.subtypes">
          <span v-text="row.item.document_specification.subtypes"/>
        </div>
      </template>

      <template v-slot:cell(actions)="row">
        <action-open-proposal-full-details :proposal="row.item"/>

        <base-action-expand-details v-if="row.item.team.team_members.length > 1"
                                    :b-table-row="row"/>
      </template>

      <template v-slot:row-details="row">
        <team-form-synchrotron :editable="editable"
                               :proposal-object-id="getProposalObjectId(row)"
                               :selector_on="selectorOn"
                               old-cryoem-team-roles-mapping>
          <template #header>
            <span>  </span>
          </template>
        </team-form-synchrotron>
      </template>

    </b-table>

    <b-table-footer v-model="isBusy"
                    :currentPage="currentPage"
                    :data_length="proposalsLength"
                    :perPage="perPage"
                    @change_page="changePage"/>
  </div>
</template>

<script>
  import { date_regex } from '@/assets/validators/regex_validator';
  import BaseActionExpandDetails from '@/components/actions/BaseActionExpandDetails';
  import ActionOpenProposalFullDetails from '@/components/actions/ActionOpenProposalFullDetails';
  import BTableFooter from '@/components/b_table_footer';
  import ProposalFilter from '@/components/proposal_filter';
  import TeamFormSynchrotron from '@/components/team_form';
  import yesNo from '@/json/radioButtonYesNo';
  import { mapState } from 'vuex';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import BasicMailerForm from '@/components/mailer/BasicMailerForm';
  import Vue from 'vue';

  export default {
    name: 'proposals_list',
    components: {
      BaseColumnOrdinalNumber,
      ActionOpenProposalFullDetails,
      BaseActionExpandDetails,
      ProposalFilter,
      BTableFooter,
      TeamFormSynchrotron,
      BasicMailerForm,
    },
    props: {
      fields: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        selectorOn: false,
        editable: false,
        selectedCall: 'None',
        isBusy: false,
        proposals: [],
        workingProposals: [],
        currentPage: 1,
        perPage: 20,
        id: 1,
        displayCeric: true,
        query: { subtypes: ['ceric'] },
        teamComponentName: '',
        infrastructures: {
          columnOne: [
            { text: 'Synchrotron', value: 'synchrotron' },
            { text: 'CryoEm', value: 'cryoem' },
          ],
        },
        proposalStates: {
          columnOne: [
            { text: 'Draft', value: 'draft' },
            { text: 'Submitted', value: 'submitted' },
          ],
        },
        proposalGrades: {
          columnOne: [
            { text: 'Approved', value: 'approved' },
            { text: 'Rejected', value: 'rejected' },
          ],
        },
        proposalTypes: {
          columnOne: [
            { text: 'Standard', value: 'Standard' },
            { text: 'Rapid access', value: 'Rapid access' },
            { text: 'Long term', value: 'Long term' },
          ],
        },
        yesNo,
        mailContent: '',
        filterQuery: {},
        numberOfValidNamesToSend: 0,
      };
    },
    computed: {
      callsExtended() {
        return [
          ...this.calls,
          {
            name: 'None',
            start_submission: '',
            stop_submission: '',
          },
        ];
      },
      callsView() {
        return this.callsExtended.map((call) => call.name);
      },
      proposalsLength() {
        return this.proposals.length;
      },
      ...mapState('calls', ['calls']),
    },
    watch: {
      displayCeric: {
        handler() {
          if (this.displayCeric) {
            this.query.subtypes = ['ceric'];
          } else {
            this.query.subtypes = [];
          }
        },
      },
    },
    created() {
      this.loadCalls();
    },
    methods: {
      addEndStationToCryoemProposals() {
        this.proposals = this.proposals.map((proposal) => {
          if (proposal.document_specification.type === 'cryoem') {
            const general = { ...proposal.general, end_station: 'CryoEm' };
            return { ...proposal, general };
          }
          return proposal;
        });
      },
      changePage(val) {
        this.currentPage = val;
        this.clearWorkingProposals();
      },
      chooseCall() {
        const call = this.callsExtended.find((item) => item.name === this.selectedCall);
        if (call) {
          this.$set(this.query, 'from', call.start_submission.slice(0, 10));
          this.$set(this.query, 'to', call.stop_submission.slice(0, 10));
          this.$set(this.query, 'call_name', call.name);
        }
      },
      clearWorkingProposals() {
        this.$set(this, 'workingProposals', JSON.parse(JSON.stringify(this.proposals)));
      },
      concatenateProposalSubtypes() {
        this.proposals.forEach((el) => {
          const { document_specification } = el;
          const { subtype } = document_specification;
          if (subtype) {
            document_specification.subtypes = Object.keys(subtype).join(', ');
          }
        });
      },
      async getMailContent() {
        this.axios.get('/mails/proposals_grades', { params: this.query }).then((response) => {
          this.mailContent = response.data.mail_content;
          this.filterQuery = this.query;
          this.numberOfValidNamesToSend = response.data.number_of_mails_to_send;
        }).catch(() => {
          const notification = { title: 'There is no valid mails to send', type: 'error' };
          Vue.notify(notification);
        });
      },
      getProposalObjectId(row) {
        return row.item._id.$oid;
      },
      goToTeam(row) {
        this.$router.replace(`/operational/proposals/${row.item._id.$oid}`)
          .then(() => row.toggleDetails());
      },
      isInfrastructuresChosenToSendGroupMails() {
        if (this.query.infrastructures) {
          return this.query.infrastructures.length === 1;
        }
        return false;
      },
      isProposalStateChosen(state) {
        if (this.query.proposal_states) {
          return (this.query.proposal_states.includes(state) && (this.query.proposal_states.length === 1));
        }
        return false;
      },
      isProposalsGradesChosenToSendGroupMails() {
        if ('proposal_grades' in this.query) {
          return this.query.proposal_grades.length === 1;
        }
        return false;
      },
      isProposalsTypesChosenToSendGroupMails() {
        if (this.query.infrastructures.includes('cryoem')) {
          return true;
        }
        if ('proposal_types' in this.query) {
          return this.query.proposal_types.length === 1;
        }
        return false;
      },
      async loadCalls() {
        await this.$store.dispatch('calls/load_calls');

        // setup the default selected call (None)
        this.selectedCall = this.callsView[this.callsView.length - 1];
      },
      async loadData() {
        this.toggleBusy();
        const response = await this.axios.get('/documents', { params: this.query });
        this.$store.commit('login/proposals_query', this.query);
        this.proposals = response.data;
        this.concatenateProposalSubtypes();
        this.addEndStationToCryoemProposals();
        this.clearWorkingProposals();
        this.$emit('setup_proposal', this.proposals);
        this.toggleBusy();
        await this.getMailContent();
      },
      async openModalGroupMails() {
        let notification = {};
        if (!(this.isInfrastructuresChosenToSendGroupMails()
          && this.isProposalsGradesChosenToSendGroupMails()
          && this.isProposalsTypesChosenToSendGroupMails()
          && this.isProposalStateChosen('submitted'))) {
          if (!this.isProposalStateChosen('draft')) {
            notification = {
              title: 'Please select the submitted proposal state, '
                + 'a single infrastructure, single a grade and single proposal type before sending emails',
              type: 'error',
            };
            Vue.notify(notification);
            return;
          }
        }
        if (this.proposals.length === 0) {
          notification = { title: 'Without any loaded proposals, you cannot send group emails', type: 'error' };
          Vue.notify(notification);
        } else {
          await this.getMailContent();
          this.$refs.mailerFormModal.openModal();
        }
      },
      toggleBusy() {
        this.isBusy = !this.isBusy;
      },
    },
    validations: {
      query: {
        from: {
          isValid(date) {
            if (date !== '') {
              return !!(date_regex.regex.test(date));
            }
            return true;
          },
        },
        to: {
          isValid(date) {
            if (date !== '') {
              return !!(date_regex.regex.test(date));
            }
            return true;
          },
        },
      },
    },
  };
</script>

<style scoped>
  .invalid {
    border: 2px solid red;
    border-radius: 5px;
  }
</style>
