<template>
  <div>
    <base-submit-info-modal ref="mailerFormModal"
                            :confirm-function="showCloseConfirmationModal"
                            cancel-button-variant="warning"
                            confirm-button-title="Send mails"
                            confirm-button-variant="info"
                            modal-title="Group mails form">
      <base-submit-danger-modal ref="closeConfirmationModal"
                                :confirm-function="submitSendingGroupMails"
                                confirm-button-title="Confirm"
                                modal-title="Are you sure?">
        <p>
          Clicking "Confirm" will result in sending {{ this.numberOfValidNamesToSend }} mails to main authors
          + copies will be sent to technical roles
        </p>
      </base-submit-danger-modal>
      <b-alert show
               size="lg"
               variant="dark">

        <b>1) Button "Send mails" sends mails to the main authors of previously filtered proposals</b><br>
        <b>2) Tags {} are required to dynamically fill form. Make sure NOT to modify content in the tags</b><br>
        <b>3) Description of "context" tags {} <a href="https://wiki.synchrotron.pl/wiki/Proposal" target="_blank">
          Link
        </a></b><br>
        <b>4) For each proposal an email will also be sent to the users with role: "technical"
          (for the end station of proposal) for synchrotron proposals or "microscope_cryo_technicalManager"
          for cryo-EM proposals.
        </b><br>

        <b v-if="this.isProposalsSubmittedState">
          5) Make sure that proposals are submitted
        </b>
      </b-alert>
      <b-form-group label-class="mt-2 font-weight-bold">
        <b-form-textarea v-model="renderedMailContent"
                         id="comment"
                         :placeholder=placeholder
                         rows="24"/>
      </b-form-group>
      <p>Number of mails to send to main authors: {{this.numberOfValidNamesToSend}} + copies will be
        sent to technical roles</p>
    </base-submit-info-modal>
  </div>
</template>

<script>
  import BaseSubmitInfoModal from '@/components/baseComponents/baseModals/BaseSubmitInfoModal';
  import BaseSubmitDangerModal from '@/components/baseComponents/baseModals/BaseSubmitDangerModal';
  import Vue from 'vue';

  export default {
    name: 'BasicMailerForm',
    components: { BaseSubmitInfoModal, BaseSubmitDangerModal },
    props: {
      isProposalsSubmittedState: {
        type: Boolean,
        required: true,
      },
      mailContent: {
        type: String,
        required: true,
      },
      filterQuery: { required: true },
      numberOfValidNamesToSend: {
        type: Number,
        required: true,

      },
    },
    data() {
      return {
        placeholder: 'Please note that the input provided in this field constitutes'
          + ' the content of the emails that will be sent to users',
        renderedMailContent: '',
        submitTrigger: false,
      };
    },
    methods: {
      showCloseConfirmationModal() {
        this.$refs.closeConfirmationModal.show();
      },
      renderMailContentToModal(mailContent) {
        return mailContent.replaceAll('<p>', '')
          .replaceAll('</p>', '');
      },
      renderMailContentToSend(mailContent) {
        return `<p>${mailContent.replaceAll('\n', '</p><p>')}</p>`;
      },
      async submitSendingGroupMails() {
        let notification = {};
        try {
          const response = await this.axios.post(
            '/mails/proposals_grades',
            { mail_content: this.renderMailContentToSend(this.renderedMailContent) },
            { params: this.filterQuery },
          );

          if (response.data.data) {
            notification = { type: 'success', title: 'Mails sent successfully!' };
            Vue.notify(notification);
            this.$refs.mailerFormModal.hide();
          } else {
            notification = { type: 'error', title: 'Failed to send emails' };
            Vue.notify(notification);
          }
        } catch (error) {
          notification = { title: 'Error occurred while sending mail', type: 'error' };
          Vue.notify(notification);
        }
      },
      openModal() {
        this.$refs.mailerFormModal.show();
        this.renderedMailContent = this.renderMailContentToModal(this.mailContent);
      },
    },
  };
</script>

<style scoped>

</style>
