<template>
  <base-icon-button v-on="$listeners"
                    :title="title">
    <base-material-design-icon :icon="icon"/>
  </base-icon-button>
</template>

<script>
  import BaseIconButton from '@/components/baseComponents/baseIcons/BaseIconButton';
  import BaseMaterialDesignIcon from '@/components/baseComponents/baseIcons/BaseMaterialDesignIcon';

  export default {
    name: 'BaseIconButtonExpandLessMore',
    components: { BaseMaterialDesignIcon, BaseIconButton },
    props: {
      title: {
        type: String,
        required: true,
      },
      isExpanded: {
        type: Boolean,
        required: true,
      },
    },
    computed: {
      icon() {
        return this.isExpanded ? 'expand_less' : 'expand_more';
      },
    },
  };
</script>

<style scoped>

</style>
